import BreadCrumb from '@/components/layout/BreadCrumb/breadCrumb.vue'
import InterestedWaitingListGraphic from '@/components/charts/waitingListGraphic/interestedWaitingListGraphic.vue'


export default {
  name: 'WaitingList-Graphic',
  components: {
    [BreadCrumb.name]: BreadCrumb,
		InterestedWaitingListGraphic
  },

  data: () => ({
    showGraphic: true
  }),

  computed: {
    breadCrumb() {
      return {
        breadcrumbItems: [
          {
            text: 'Dashboard',
            href: this.$router.resolve({ name: 'Dashboard' }).href
          },
          {
            text: 'Interesses da Lista de Espera'
          }
        ]
      }
    }
  },

	created() {
    
  },

  methods: {

  }
}
