import WaitingListRepository from '@/shared/http/repositories/socialProject/waitinglist'
import { IsSuccessCode } from '@/shared/utils/API'
import { loading } from '@/shared/utils/UIHelper'
import VueHighcharts from 'vue2-highcharts'

export default {
  components: {
      VueHighcharts
  },

  props: {
    item: {
      type: Boolean,
      default: false
    }
  },

  data: () => ({
    options: {
      chart: {
        type: 'column'
      },
      title: {
        text: 'Área de interesses'
      },
      subtitle: {
        text: 'Lista de Espera'
      },
      xAxis: {
        type: 'category',
        labels: {
            rotation: -45,
            style: {
                color: '#0d75a3',
                fontSize: '13px',
                fontFamily: 'Verdana, sans-serif'
            }
        }
      },
      yAxis: {
        min: 0,
        title: {
            text: 'Crianças'
        }
      },
      legend: {
        enabled: false
      },
      tooltip: {
        pointFormat: 'Quantidade de: <b>{point.y} crianças</b> interessadas.'
      },
      series: [{
        name: 'Crianças',
        color: '#00ADEE',
        dataLabels: {
            enabled: true,
            color: '#FFFFFF',
            align: 'right',
            format: '{point.y}',
            style: {
                fontSize: '15px',
                fontFamily: 'Verdana, sans-serif'
            }
        }
      }]
    }
  }),

  watch: {
    item: {
      handler: function (value) {
        if (value) {
          loading.push()
          this.loadChargeData(value)
          loading.pop()
        }
      },
      immediate: true
    }
  },

  methods: {
    loadChargeData() {
      loading.push()
      // eslint-disable-next-line no-debugger
      debugger
      WaitingListRepository.GetGraphic()
      .then(response => {
				if(!IsSuccessCode){
					return Promise.reject()
        }

        const res = response.data.result

        let categoriesArr = []
        let dataArr = []

        res.forEach(el => {
          if (el.count > 0) {
            categoriesArr.push(el.name)
            dataArr.push(el.count)
            return
          }
          categoriesArr.push(el.name)
          dataArr.push(0)
        })        

        setTimeout(() => {
          this.$refs.lineCharts.chart.update({
            xAxis: {
              categories: categoriesArr,
              text: null
            },
            series: [{
              name: 'Média',
              data: dataArr
            }],
            title: {
              text: 'Interesses da Lista de Espera',
              align: 'center'
            },
            subtitle: {
              text: 'Interesses por Locais de Treinamentos',
              align: 'center'
            }
          })

          loading.pop()
        }, 1300)
      })
      .catch(e => {
        loading.pop()
        console.log(`Erro ao tentar obter os dados para o gráfico interesse da lista de espera: \n ${e}`)
      })
    }
  }
}