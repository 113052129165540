import SocialProjectManager from '../../clients/abdaSocialProjectManager'
import { merge } from 'lodash'

const resourceName = 'waitinglist'

const GetAll = (criteria, pagination) => SocialProjectManager.get(resourceName, { 
  params: merge(criteria, pagination) 
  })

const GetById = id => SocialProjectManager.get(`${resourceName}/${id}`)

const Create = data => SocialProjectManager.post(resourceName, data)

const Update = data => SocialProjectManager.put(`${resourceName}/${data.id}`, data)

const Delete = id => SocialProjectManager.delete(`${resourceName}/${id}`)

const DeleteAndMatriculated = (id, testId) => SocialProjectManager.delete(`${resourceName}/inactive/matriculated/${id}/test/${testId}`)

const Matriculated = (id, testId) => SocialProjectManager.put(`${resourceName}/matriculated/${id}/test/${testId}`)

const GetAdditionalInformation = id => SocialProjectManager.get(`${resourceName}/${id}/additional`)

const GetGraphic = () => SocialProjectManager.get(`${resourceName}/graphic`)

const GetAccountUpdated = id => SocialProjectManager.get(`${resourceName}/account-id/${id}`)

const ExportExcel = criteria => SocialProjectManager.get(`${resourceName}/download-excel`, { params: criteria })


const WaitingListRepository = {
  GetById,
  GetAll,
  Create,
  Update,
  Delete,
  GetAdditionalInformation,
  GetAccountUpdated,
  DeleteAndMatriculated,
  Matriculated,
  GetGraphic,
  ExportExcel
}

export default WaitingListRepository